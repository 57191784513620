export default [

  // *===============================================---*
  // *--------- Site Web  ---------------------------------------*
  // *===============================================---*
  {
    path: '/',
    component: () => import('@/layouts/SiteLayout/SiteLayout.vue'),
    meta: {
      layout: 'full',
    },
    children: [
      // Home Page
      {
        path: '/',
        name: 'accueil',
        component: () => import('@/views/site/Index.vue'),
      },
      // Affrêteur
      {
        path: '/affreteurs',
        name: 'affreteurs',
        component: () => import('@/views/site/Affreteur.vue'),
      },
      // Documenthèque
      {
        path: '/documentheque',
        component: () => import('@/views/site/Documentheque.vue'),
      },
      // Transporteur
      {
        path: '/transporteurs',
        name: 'transporteurs',
        component: () => import('@/views/site/Transporteur.vue'),
      },
      // Contact
      {
        path: '/contact',
        name: 'contact',
        component: () => import('@/views/site/Contact.vue'),
      },

    ],
  },
]
