// eslint-disable-next-line import/no-cycle
import store from '@/store'

export default [

  // *===============================================---*
  // *--------- STETRANSPORT  ---------------------------------------*
  // *===============================================---*
  {
    path: '/espace-expert',
    redirect: '/espace-expert/dashboard',
    component: () => import('@/layouts/espace-expert/ExpertLayout.vue'),
    beforeEnter: (to, from, next) => {
      const data = JSON.parse(store.state.authStore.user)
      if (data.role === 'ROLE_EXPERT') next()
      else next({ name: 'misc-not-authorized' })
    },
    meta: {
      layout: 'vertical',
      redirectIfLoggedIn: true,
    },
    children: [
      // experts
      {
        path: '/espace-expert/dashboard',
        name: 'espace-expert.dashboard',
        component: () => import('@/views/app/espace-expert/Home.vue'),
        meta: {
          redirectIfLoggedIn: true,
        },
      },
      {
        path: '/espace-expert/expertises-traitees',

        name: 'espace-expert.expertises-traitees',

        component: () => import('@/views/app/espace-expert/expertises/DemandesResolues.vue'),
        meta: {
          redirectIfLoggedIn: true,
        },
      },
      {
        path: '/espace-expert/expertises-encours',

        name: 'espace-expert.expertises-encours',

        component: () => import('@/views/app/espace-expert/expertises/DemandesEncours.vue'),
        meta: {
          redirectIfLoggedIn: true,
        },
      },
      // Expertises soumis
      // {
      //   path: '/espace-expert/demandes-soumises',

      //   name: 'espace-expert.expertises-soumises',

      //   component: () => import('@/views/app/espace-expert/plaintes/ExpertiseSoumise.vue'),
      //   meta: {
      //     redirectIfLoggedIn: true,
      //   },
      // },
      {
        path: '/espace-expert/:id/detail-expertise',
        name: 'espace-expert.detail-expertise',
        component: () => import('@/views/app/espace-expert/expertises/DetailDemande.vue'),
        meta: {
          pageTitle: 'Détail la question',
          redirectIfLoggedIn: true,

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/espace-admin/dashboard',

            },
            {
              text: 'Détail de la demande d\'expertise',
              active: true,
            },
          ],
        },
      },
      // Liste des pièces
      {
        path: '/espace-expert/activate-account',
        name: 'espace-expert.activate-account',
        component: () => import('@/views/app/espace-expert/manage-profile/ActivateAccount.vue'),
        meta: {
          redirectIfLoggedIn: true,

        },
      },
      // Profile
      {
        path: '/espace-expert/profile',
        name: 'espace-expert.profile',
        component: () => import('@/views/app/espace-expert/manage-profile/AccountSetting.vue'),
        meta: {
          pageTitle: 'Gestion de profil',
          redirectIfLoggedIn: true,

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/espace-expert/dashboard',

            },
            {
              text: 'Gestion de profil',
              active: true,

            },
          ],
        },
      },

    ],
  },
]
