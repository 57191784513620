// eslint-disable-next-line import/no-cycle
import store from '@/store'

export default [

  // *===============================================---*
  // *--------- AFFRETEUR  ---------------------------------------*
  // *===============================================---*
  {
    path: '/affreteur',
    redirect: '/espace-affreteur/dashboard',
    component: () => import('@/layouts/espace-affreteur/AffreteurLayout.vue'),
    beforeEnter: (to, from, next) => {
      const data = JSON.parse(store.state.authStore.user)
      if (data.role === 'ROLE_AFFRETEUR') next()
      else next({ name: 'misc-not-authorized' })
    },
    meta: {
      layout: 'vertical',
      redirectIfLoggedIn: true,
    },
    children: [
      // Dashboard
      {
        path: '/espace-affreteur/dashboard',
        name: 'espace-affreteur.dashboard',
        component: () => import('@/views/app/espace-affreteur/Home.vue'),
        beforeEnter: (to, from, next) => {
          const data = JSON.parse(store.state.authStore.user)
          if (data.role === 'ROLE_AFFRETEUR') next()
          else next({ name: 'misc-not-authorized' })
        },
        meta: {
          pageTitle: 'Tableau de bord',
          redirectIfLoggedIn: true,
          breadcrumb: [
            {
              text: 'Affreteur',
            },
            {
              text: 'Tableau de bord',
              active: true,
            },
          ],
        },
      },
      // soumettre une plainte
      {
        path: '/espace-affreteur/demande-expertise',

        name: 'espace-affreteur.demande-expertise',

        component: () => import('@/views/app/espace-affreteur/expertises/AjouterDemande.vue'),
        meta: {
          redirectIfLoggedIn: true,
        },
      },
      {
        path: '/espace-affreteur/expertises-traitees',

        name: 'espace-affreteur.expertises-traitees',

        component: () => import('@/views/app/espace-affreteur/expertises/DemandeTraitee.vue'),
        meta: {
          redirectIfLoggedIn: true,
        },
      },
      // Demandes soumises
      {
        path: '/espace-affreteur/demandes-soumises',

        name: 'espace-affreteur.expertises-soumises',

        component: () => import('@/views/app/espace-affreteur/expertises/DemandeSoumise.vue'),
        meta: {
          redirectIfLoggedIn: true,
        },
      },
      {
        path: '/espace-affreteur/:id/detail-expertise',
        name: 'espace-affreteur.detail-expertise',
        component: () => import('@/views/app/espace-affreteur/expertises/DetailDemande.vue'),
        meta: {
          pageTitle: 'Détail la question',
          redirectIfLoggedIn: true,

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/espace-admin/dashboard',

            },
            {
              text: 'Détail de la question',
              active: true,

            },
          ],
        },
      },
      // Nouvelle Offre
      {
        path: '/espace-affreteur/offres/nouvelle',
        name: 'espace-affreteur.nouvelle.offre',
        component: () => import('@/views/app/espace-affreteur/offers/New.vue'),
        meta: {
          redirectIfLoggedIn: true,
        },
      },
      // Modifier Offre
      {
        path: '/espace-affreteur/offres/update/:code',
        name: 'espace-affreteur.nouvelle.update',
        component: () => import('@/views/app/espace-affreteur/offers/UpdateOffer.vue'),
        meta: {
          redirectIfLoggedIn: true,
        },
      },
      //  Offre en attente
      {
        path: '/espace-affreteur/offres/created',
        name: 'espace-affreteur.created.offre',
        component: () => import('@/views/app/espace-affreteur/offers/Created.vue'),
        meta: {
          redirectIfLoggedIn: true,
        },
      },
      //  Offre annulee
      {
        path: '/espace-affreteur/offres/canceled',
        name: 'espace-affreteur.canceled.offre',
        component: () => import('@/views/app/espace-affreteur/offers/Canceled.vue'),
        meta: {
          redirectIfLoggedIn: true,
        },
      },
      //  Offre cloturée
      {
        path: '/espace-affreteur/offres/closed',
        name: 'espace-affreteur.closed.offre',
        component: () => import('@/views/app/espace-affreteur/offers/Closed.vue'),
        meta: {
          redirectIfLoggedIn: true,
        },
      },

      // Liste des factures
      {
        path: '/espace-affreteur/liste-facture',

        name: 'espace-affreteur.liste-facture',

        component: () => import('@/views/app/espace-affreteur/invoice/Index.vue'),
        meta: {
          redirectIfLoggedIn: true,
        },
      },
      // Détail Facture
      {
        path: '/espace-affreteur/:code/detail-facture',

        name: 'espace-affreteur.detail-facture',

        component: () => import('@/views/app/espace-affreteur/invoice/DetailInvoice.vue'),
        meta: {
          redirectIfLoggedIn: true,
        },
      },
      // Adresse affreteur
      {
        path: '/espace-affreteur/freight-address/all',

        name: 'espace-affreteur.all.adresse',

        component: () => import('@/views/app/espace-affreteur/address/All.vue'),
        meta: {
          redirectIfLoggedIn: true,
        },
      },
      // Ajouter  Adresse affreteur
      {
        path: '/espace-affreteur/freight-address/new',

        name: 'espace-affreteur.new.adresse',

        component: () => import('@/views/app/espace-affreteur/address/New.vue'),
        meta: {
          redirectIfLoggedIn: true,
        },
      },
      // Disponibilites affreteur
      {
        path: '/espace-affreteur/disponilities',

        name: 'espace-affreteur.disponilities',
        component: () => import('@/views/app/espace-affreteur/transport/Disponibility.vue'),
        meta: {
          redirectIfLoggedIn: true,
        },
      },
      // Tracking
      {
        path: '/espace-affreteur/tracking',

        name: 'espace-affreteur.tracking',
        component: () => import('@/views/app/espace-affreteur/tracking/Tracking.vue'),
        meta: {
          redirectIfLoggedIn: true,
        },
      },
      // Diffusion
      {
        path: '/espace-affreteur/diffusion',

        name: 'espace-affreteur.diffusion',
        component: () => import('@/views/app/espace-affreteur/diffusion/Liste.vue'),
        meta: {
          redirectIfLoggedIn: true,
        },
      },

      // Détail de l'Offre
      {
        path: '/espace-affreteur/:code/details',
        name: 'espace-affreteur.details',
        component: () => import('@/views/app/espace-affreteur/offers/DetailOffres.vue'),
        meta: {
          redirectIfLoggedIn: true,

        },
      },
      // Nouvelle offre
      {
        path: '/espace-affreteur/new',
        name: 'espace-affreteur.new',
        component: () => import('@/views/app/espace-affreteur/offers/Index.vue'),
        meta: {
          redirectIfLoggedIn: true,

        },
      },
      // Liste de devis reçus
      {
        path: '/espace-affreteur/devis',
        name: 'espace-affreteur.devis',
        component: () => import('@/views/app/espace-affreteur/devis/DevisRecus.vue'),
        meta: {
          redirectIfLoggedIn: true,

        },
      },
      // Liste de devis dejeté
      {
        path: '/espace-affreteur/devis-rejet',
        name: 'espace-affreteur.devis-rejet',
        component: () => import('@/views/app/espace-affreteur/devis/DevisRejete.vue'),
        meta: {
          redirectIfLoggedIn: true,

        },
      },
      // Liste de devis dejeté
      {
        path: '/espace-affreteur/devis-acceptes',
        name: 'espace-affreteur.devis-acceptes',
        component: () => import('@/views/app/espace-affreteur/devis/DevisAcceptes.vue'),
        meta: {
          redirectIfLoggedIn: true,

        },
      },
      // Liste des pièces
      {
        path: '/espace-affreteur/activate-account',
        name: 'espace-affreteur.activate-account',
        component: () => import('@/views/app/espace-affreteur/ActivateAccount.vue'),
        meta: {
          redirectIfLoggedIn: true,

        },
      },
      // Profile
      {
        path: '/espace-affreteur/profile',
        name: 'espace-affreteur.profile',
        component: () => import('@/views/app/espace-affreteur/manage-profile/AccountSetting.vue'),
        meta: {
          pageTitle: 'Gestion de profil',
          redirectIfLoggedIn: true,

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/espace-affreteur/dashboard',

            },
            {
              text: 'Gestion de profil',
              active: true,

            },
          ],
        },
      },

    ],
  },
]
