import Vue from 'vue'
import VueRouter from 'vue-router'
// eslint-disable-next-line import/no-cycle
import store from '@/store'

// eslint-disable-next-line import/no-cycle
import admin from '@/router/routes/admin'
// eslint-disable-next-line import/no-cycle
import affreteur from '@/router/routes/affreteur'
// eslint-disable-next-line import/no-cycle
import societe from '@/router/routes/societe'
// eslint-disable-next-line import/no-cycle
import transporteur from '@/router/routes/transporteur'
// eslint-disable-next-line import/no-cycle
import auth from '@/router/routes/auth'
import site from '@/router/routes/home'
// eslint-disable-next-line import/no-cycle
import useAuthUser from '@/services/authentification/userService'
import expert from './routes/expert'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...admin,
    ...affreteur,
    ...societe,
    ...transporteur,
    ...auth,
    ...site,
    ...expert,
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/not-authorized',
      name: 'misc-not-authorized',
      component: () => import('@/views/error/NotAuthorized.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach(async (to, from, next) => {
  const TOKEN = store.state.authStore.token

  if (to.meta.redirectIfLoggedIn && TOKEN === null) {
    localStorage.clear()

    next({ name: 'auth-login' })
  } else if (TOKEN) {
    const { getUserAuth } = useAuthUser()
    try {
      await getUserAuth(TOKEN)
      next()
    } catch (error) {
      store.state.authStore.token = null
      store.state.authStore.authenticated = false
      store.commit('setUser', {})
      localStorage.clear()
      console.clear()
      next({ name: 'auth-login' })
    }
  } else {
    next()
  }
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
