// eslint-disable-next-line import/no-cycle
import store from '@/store'

export default [

  // *===============================================---*
  // *--------- STETRANSPORT  ---------------------------------------*
  // *===============================================---*
  {
    path: '/espace-transporteur',
    redirect: '/espace-transporteur/dashboard',
    component: () => import('@/layouts/espace-transporteur-independant/TransporteurIndependantLayout.vue'),
    beforeEnter: (to, from, next) => {
      const data = JSON.parse(store.state.authStore.user)
      if (data.role === 'ROLE_TRANSPORTEUR_INDEPENDANT') next()
      else next({ name: 'misc-not-authorized' })
    },
    meta: {
      layout: 'vertical',
      redirectIfLoggedIn: true,
    },
    children: [
      // stetransport
      {
        path: '/espace-transporteur/dashboard',
        name: 'espace-transporteur.dashboard',
        component: () => import('@/views/app/espace-transporteur/independant/Home.vue'),
        meta: {
          redirectIfLoggedIn: true,
        },
      },
      // disponible
      {
        path: '/espace-transporteur/disponible',

        name: 'espace-transporteur.disponible',

        component: () => import('@/views/app/espace-transporteur/independant/disponible/Disponible.vue'),
        meta: {
          redirectIfLoggedIn: true,
        },
      },
      // soumettre une plainte
      {
        path: '/espace-transporteur/demande-expertise',

        name: 'espace-transporteur.demande-expertise',

        component: () => import('@/views/app/espace-transporteur/independant/expertises/AjouterDemande.vue'),
        meta: {
          redirectIfLoggedIn: true,
        },
      },
      {
        path: '/espace-transporteur/expertises-traitees',

        name: 'espace-transporteur.expertises-traitees',

        component: () => import('@/views/app/espace-transporteur/independant/expertises/DemandeTraitee.vue'),
        meta: {
          redirectIfLoggedIn: true,
        },
      },
      // Demandes soumises
      {
        path: '/espace-transporteur/demandes-soumises',

        name: 'espace-transporteur.expertises-soumises',

        component: () => import('@/views/app/espace-transporteur/independant/expertises/DemandeSoumise.vue'),
        meta: {
          redirectIfLoggedIn: true,
        },
      },
      {
        path: '/espace-transporteur/:id/detail-expertise',
        name: 'espace-transporteur.detail-expertise',
        component: () => import('@/views/app/espace-transporteur/independant/expertises/DetailDemande.vue'),
        meta: {
          pageTitle: 'Détail la question',
          redirectIfLoggedIn: true,

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/espace-admin/dashboard',

            },
            {
              text: 'Détail de la question',
              active: true,

            },
          ],
        },
      },

      // Détail de l'Offre
      {
        path: '/espace-transporteur/:code/details',
        name: 'espace-transporteur.details',
        component: () => import('@/views/app/espace-transporteur/independant/disponible/DetailOffres.vue'),
        meta: {
          redirectIfLoggedIn: true,

        },
      },
      // soumettre un devis
      {
        path: '/espace-transporteur/:code/soumettre-devis',

        name: 'espace-transporteur.soumettre-devis',
        component: () => import('@/views/app/espace-transporteur/independant/disponible/devis/SoumettreDevis.vue'),
        meta: {
          redirectIfLoggedIn: true,
        },
      },

      // devis soumis
      {
        path: '/espace-transporteur/devis-soumis',

        name: 'espace-transporteur.devis-soumis',

        component: () => import('@/views/app/espace-transporteur/independant/disponible/devis/DevisSoumis.vue'),
        meta: {
          redirectIfLoggedIn: true,
        },
      },

      // Devis rejete
      {
        path: '/espace-transporteur/devis-rejete',

        name: 'espace-transporteur.devis-rejete',

        component: () => import('@/views/app/espace-transporteur/independant/disponible/devis/DevisRejete.vue'),
        meta: {
          redirectIfLoggedIn: true,
        },
      },
      // Devis suspendu
      {
        path: '/espace-transporteur/devis-suspendu',

        name: 'espace-transporteur.devis-suspendu',

        component: () => import('@/views/app/espace-transporteur/independant/disponible/devis/DevisSuspendus.vue'),
        meta: {
          redirectIfLoggedIn: true,
        },
      },
      // Devis accepte
      {
        path: '/espace-transporteur/devis-accepte',

        name: 'espace-transporteur.devis-accepte',

        component: () => import('@/views/app/espace-transporteur/independant/disponible/devis/DevisAcceptes.vue'),
        meta: {
          redirectIfLoggedIn: true,
        },
      },
      // Devis detail
      {
        path: '/espace-transporteur/:code/detail-devis',

        name: 'espace-transporteur.detail-devis',

        component: () => import('@/views/app/espace-transporteur/independant/disponible/devis/DetailDevis.vue'),
        meta: {
          redirectIfLoggedIn: true,
        },
      },
      // Modifier un devis
      {
        path: '/espace-transporteur/:code/modifier-devis',

        name: 'espace-transporteur.modifier-devis',

        component: () => import('@/views/app/espace-transporteur/independant/disponible/devis/ModifierDevis.vue'),
        meta: {
          redirectIfLoggedIn: true,
        },
      },
      // Liste des factures
      {
        path: '/espace-transporteur/liste-facture',

        name: 'espace-transporteur.liste-facture',

        component: () => import('@/views/app/espace-transporteur/independant/invoice/Index.vue'),
        meta: {
          redirectIfLoggedIn: true,
        },
      },
      // Détail Facture
      {
        path: '/espace-transporteur/:code/detail-facture',

        name: 'espace-transporteur.detail-facture',

        component: () => import('@/views/app/espace-transporteur/independant/invoice/DetailInvoice.vue'),
        meta: {
          redirectIfLoggedIn: true,
        },
      },
      // Transporteurs
      {
        path: '/espace-transporteur/transporteurs',

        name: 'espace-transporteur.transporteurs',

        component: () => import('@/views/app/espace-transporteur/independant/transporteur/Transporteur.vue'),
        meta: {
          redirectIfLoggedIn: true,
        },
      },
      // Moyens de transport
      {
        path: '/espace-transporteur/moyen-de-transport',

        name: 'espace-transporteur.moyen-de-transport',

        component: () => import('@/views/app/espace-transporteur/independant/moyen-de-transport/MoyenTransport.vue'),
        meta: {
          redirectIfLoggedIn: true,
        },
      },
      // Ajouter moyens de transport
      {
        path: '/espace-transporteur/ajouter',

        name: 'espace-transporteur.ajouter',

        component: () => import('@/views/app/espace-transporteur/independant/moyen-de-transport/AjouterMoyenTransport.vue'),
        meta: {
          redirectIfLoggedIn: true,
        },
      },
      // Disponibilites transporteur
      {
        path: '/espace-transporteur/disponilities',

        name: 'espace-transporteur.disponilities',
        component: () => import('@/views/app/espace-transporteur/independant/transport/Disponibility.vue'),
        meta: {
          redirectIfLoggedIn: true,
        },
      },
      // Tracking
      {
        path: '/espace-transporteur/tracking',

        name: 'espace-transporteur.tracking',
        component: () => import('@/views/app/espace-transporteur/independant/tracking/Tracking.vue'),
        meta: {
          redirectIfLoggedIn: true,
        },
      },
      // Liste des pièces
      {
        path: '/espace-transporteur/activate-account',
        name: 'espace-transporteur.activate-account',
        component: () => import('@/views/app/espace-transporteur/independant/manage-profile/ActivateAccount.vue'),
        meta: {
          redirectIfLoggedIn: true,

        },
      },
      // Profile
      {
        path: '/espace-transporteur/profile',
        name: 'espace-transporteur.profile',
        component: () => import('@/views/app/espace-transporteur/independant/manage-profile/AccountSetting.vue'),
        meta: {
          pageTitle: 'Gestion de profil',
          redirectIfLoggedIn: true,

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/espace-transporteur/dashboard',

            },
            {
              text: 'Gestion de profil',
              active: true,

            },
          ],
        },
      },

    ],
  },
]
