// eslint-disable-next-line import/no-cycle
import store from '@/store'

export default [

  // *===============================================---*
  // *--------- AUTH  ---------------------------------------*
  // *===============================================---*
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/authentification/Login.vue'),
    beforeEnter: (to, from, next) => {
      const data = JSON.parse(store.state.authStore.user)
      if (data && data.role === 'ROLE_ADMIN') next({ name: 'espace-admin.dashboard' })
      else if (data && data.role === 'ROLE_AFFRETEUR') next({ name: 'espace-affreteur.dashboard' })
      else if (data && data.role === 'ROLE_SOCIETE_TRANSPORT') next({ name: 'espace-affreteur.dashboard' })
      else if (data && data.role === 'ROLE_TRANSPORTEUR_INDEPENDANT') next({ name: 'espace-transporteur.dashboard' })
      else next()
    },
    meta: {
      layout: 'full',
    },

  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/authentification/ForgotPassword.vue'),
    beforeEnter: (to, from, next) => {
      const data = JSON.parse(store.state.authStore.user)
      if (data && data.role === 'ROLE_ADMIN') next({ name: 'espace-admin.dashboard' })
      else if (data && data.role === 'ROLE_AFFRETEUR') next({ name: 'espace-affreteur.dashboard' })
      else if (data && data.role === 'ROLE_SOCIETE_TRANSPORT') next({ name: 'espace-affreteur.dashboard' })
      else if (data && data.role === 'ROLE_TRANSPORTEUR_INDEPENDANT') next({ name: 'espace-transporteur.dashboard' })
      else next()
    },
    meta: {
      layout: 'full',
    },

  },
  {
    path: '/reset-password',
    name: 'auth-reset-password',
    component: () => import('@/views/authentification/ResetPassword.vue'),
    beforeEnter: (to, from, next) => {
      const data = JSON.parse(store.state.authStore.user)
      if (data && data.role === 'ROLE_ADMIN') next({ name: 'espace-admin.dashboard' })
      else if (data && data.role === 'ROLE_AFFRETEUR') next({ name: 'espace-affreteur.dashboard' })
      else if (data && data.role === 'ROLE_SOCIETE_TRANSPORT') next({ name: 'espace-affreteur.dashboard' })
      else if (data && data.role === 'ROLE_TRANSPORTEUR_INDEPENDANT') next({ name: 'espace-transporteur.dashboard' })
      else next()
    },
    meta: {
      layout: 'full',

    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/authentification/CreateAccount.vue'),
    beforeEnter: (to, from, next) => {
      const data = JSON.parse(store.state.authStore.user)
      if (data && data.role === 'ROLE_ADMIN') next({ name: 'espace-admin.dashboard' })
      else if (data && data.role === 'ROLE_AFFRETEUR') next({ name: 'espace-affreteur.dashboard' })
      else if (data && data.role === 'ROLE_SOCIETE_TRANSPORT') next({ name: 'espace-affreteur.dashboard' })
      else if (data && data.role === 'ROLE_TRANSPORTEUR_INDEPENDANT') next({ name: 'espace-transporteur.dashboard' })
      else if (data && data.role === 'ROLE_EXPERT') next({ name: 'espace-expert.dashboard' })
      else next()
    },
    meta: {
      layout: 'full',

    },
  },
  {
    path: '/expert-register',
    name: 'expert-register',
    component: () => import('@/views/app/espace-expert/InscriptionExpert'),
    beforeEnter: (to, from, next) => {
      const data = JSON.parse(store.state.authStore.user)
      if (data && data.role === 'ROLE_EXPERT') next({ name: 'espace-expert.dashboard' })
      else next()
    },
    meta: {
      layout: 'full',

    },
  },
  {
    path: '/resend-verification-email',
    name: 'resend-verification-email',
    component: () => import('@/views/authentification/VerifyEmail.vue'),
    // beforeEnter: (to, from, next) => {
    //   const data = JSON.parse(store.state.authStore.user)
    //   if (data && data.role === 'ROLE_ADMIN') next({ name: 'espace-admin.dashboard' })
    //   else if (data && data.role === 'ROLE_AFFRETEUR') next({ name: 'espace-affreteur.dashboard' })
    //   else if (data && data.role === 'ROLE_SOCIETE_TRANSPORT') next({ name: 'espace-affreteur.dashboard' })
    //   else if (data && data.role === 'ROLE_TRANSPORTEUR_INDEPENDANT') next({ name: 'espace-transporteur.dashboard' })
    //   else if (data && data.token) next()
    //   else next({ name: 'auth-login' })
    // },
    meta: {
      layout: 'full',
      redirectIfLoggedIn: true,
    },
  },

]
