// eslint-disable-next-line import/no-cycle
import store from '@/store'

export default [

  // *===============================================---*
  // *--------- ROUTES ADMINISTRATEURS  ---------------------------------------*
  // *===============================================---*
  {
    path: '/espace-admin',
    redirect: '/espace-admin/dashboard',
    component: () => import('@/layouts/espace-admin/AdminLayout.vue'),
    beforeEnter: (to, from, next) => {
      const data = JSON.parse(store.state.authStore.user)
      if (data.role === 'ROLE_ADMIN') next()
      else next({ name: 'misc-not-authorized' })
    },
    meta: {
      layout: 'vertical',
      redirectIfLoggedIn: true,
    },
    children: [
      // Dashboard
      {
        path: '/espace-admin/dashboard',
        name: 'espace-admin.dashboard',

        component: () => import('@/views/app/espace-admin/Home.vue'),

        meta: {
          pageTitle: 'Tableau de bord',
          redirectIfLoggedIn: true,

          breadcrumb: [
            { text: 'Tableau de bord', active: true },
          ],
        },
      },
      // Devise
      {
        path: '/espace-admin/devise',
        name: 'espace-admin.devise',
        component: () => import('@/views/app/espace-admin/settings/devise/Devise.vue'),
        meta: {
          pageTitle: 'Devise',
          redirectIfLoggedIn: true,

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/espace-admin/dashboard',

            },
            {
              text: 'Devise',
              active: true,

            },
          ],
        },
      },
      // Activation de compte
      {
        path: '/espace-admin/activate-account',
        name: 'espace-admin.activateaccount',
        component: () => import('@/views/app/espace-admin/settings/activate-account/ActivateAccount.vue'),
        meta: {
          pageTitle: 'Activation de compte',
          redirectIfLoggedIn: true,

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/espace-admin/dashboard',

            },
            {
              text: 'Activation de compte',
              active: true,

            },
          ],
        },
      },
      // Manage User
      {
        path: '/espace-admin/manage-user',
        name: 'espace-admin.manage.user',
        component: () => import('@/views/app/espace-admin/settings/activate-account/ManageUser.vue'),
        meta: {
          pageTitle: 'Gestion des utilisateurs',
          redirectIfLoggedIn: true,

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/espace-admin/dashboard',

            },
            {
              text: 'Gestion des utilisateurs',
              active: true,

            },
          ],
        },
      },
      // Offre de validation
      {
        path: '/espace-admin/offers',
        name: 'espace-admin.offers',
        component: () => import('@/views/app/espace-admin/settings/offres/Offres.vue'),
        meta: {
          pageTitle: 'Validation des offres',
          redirectIfLoggedIn: true,

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/espace-admin/dashboard',

            },
            {
              text: 'Validation des offres',
              active: true,

            },
          ],
        },
      },
      // Détail de l'Offre
      {
        path: '/espace-admin/:code/details',
        name: 'espace-admin.details',
        component: () => import('@/views/app/espace-admin/settings/offres/DetailOffres.vue'),
        meta: {
          pageTitle: 'Détails de l\'offre',
          redirectIfLoggedIn: true,

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/espace-admin/dashboard',

            },
            {
              text: 'Détails de l\'offre',
              active: true,

            },
          ],
        },
      },
      // Document
      {
        path: '/espace-admin/document',
        name: 'espace-admin.document',
        component: () => import('@/views/app/espace-admin/settings/document/Document.vue'),
        meta: {
          pageTitle: 'Document',
          redirectIfLoggedIn: true,

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/espace-admin/dashboard',

            },
            {
              text: 'Document',
              active: true,

            },
          ],
        },
      },
      // Documentation
      {
        path: '/espace-admin/documentation',
        name: 'espace-admin.documentation',
        component: () => import('@/views/app/espace-admin/settings/documentheque/Documentation.vue'),
        meta: {
          pageTitle: 'Documentation',
          redirectIfLoggedIn: true,

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/espace-admin/dashboard',

            },
            {
              text: 'Documentation',
              active: true,

            },
          ],
        },
      },
      // Unité de Mésure
      {
        path: '/espace-admin/unit-type',
        name: 'espace-admin.unittype',
        component: () => import('@/views/app/espace-admin/settings/unite-de-mesure/UniteMesure.vue'),
        meta: {
          pageTitle: 'Unité de Mesure',
          redirectIfLoggedIn: true,

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/espace-admin/dashboard',

            },
            {
              text: 'Unité de Mesure',
              active: true,

            },
          ],
        },
      },
      // Commentaires
      {
        path: '/espace-admin/comment',
        name: 'espace-admin.comment',
        component: () => import('@/views/app/espace-admin/settings/comment/Comment.vue'),
        meta: {
          pageTitle: 'Commentaire',
          redirectIfLoggedIn: true,

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/espace-admin/dashboard',

            },
            {
              text: 'Commentaire',
              active: true,

            },
          ],
        },
      },
      // Pays
      {
        path: '/espace-admin/pays',
        name: 'espace-admin.pays',
        component: () => import('@/views/app/espace-admin/settings/donnees-geographiques/pays/Pays.vue'),
        meta: {
          pageTitle: 'Pays',
          redirectIfLoggedIn: true,

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/espace-admin/dashboard',

            },
            {
              text: 'Pays',
              active: true,

            },
          ],
        },
      },
      // Département
      {
        path: '/espace-admin/pays/:slug/departement',
        name: 'espace-admin.departement',
        component: () => import('@/views/app/espace-admin/settings/donnees-geographiques/departement/Departement.vue'),
        meta: {
          pageTitle: 'Département',
          redirectIfLoggedIn: true,

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/espace-admin/dashboard',

            },
            {
              text: 'Département',
              active: true,

            },
          ],
        },
      },
      // Type de l'expert
      {
        path: '/espace-admin/type-extert',
        name: 'espace-admin.typeexpert',
        component: () => import('@/views/app/espace-admin/settings/expertises/TypeExpert.vue'),
        meta: {
          pageTitle: 'Type d\'experts',
          redirectIfLoggedIn: true,

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/espace-admin/dashboard',

            },
            {
              text: 'Type d\'expert',
              active: true,

            },
          ],
        },
      },
      // Type de l'expert
      {
        path: '/espace-admin/type-extertise',
        name: 'espace-admin.typeexpertise',
        component: () => import('@/views/app/espace-admin/settings/expertises/TypeExpertise.vue'),
        meta: {
          pageTitle: 'Type d\'expertises',
          redirectIfLoggedIn: true,

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/espace-admin/dashboard',

            },
            {
              text: 'Type d\'expertises',
              active: true,

            },
          ],
        },
      },
      // Détails d'un compte expert
      {
        path: '/espace-admin/expert/:id',
        name: 'espace-admin.details-expert',
        component: () => import('@/views/app/espace-admin/settings/expertises/DetailsExpert.vue'),
        meta: {
          pageTitle: 'Détails d\'un compte expert',
          redirectIfLoggedIn: true,

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/espace-admin/dashboard',

            },
            {
              text: 'Détails d\'un compte expert',
              active: true,

            },
          ],
        },
      },
      // Ville
      {
        path: '/espace-admin/departement/:slug/ville',
        name: 'espace-admin.ville',
        component: () => import('@/views/app/espace-admin/settings/donnees-geographiques/ville/Ville.vue'),
        meta: {
          pageTitle: 'Ville',
          redirectIfLoggedIn: true,

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/espace-admin/dashboard',

            },
            {
              text: 'Ville',
              active: true,

            },
          ],
        },
      },

      // Catégorie de camion
      {
        path: '/espace-admin/categorie-camion',
        name: 'espace-admin.transport',
        component: () => import('@/views/app/espace-admin/settings/modele-de-camion/categorie-de-camion/CategorieCamion.vue'),
        meta: {
          pageTitle: 'Catégorie de camion',
          redirectIfLoggedIn: true,
          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/espace-admin/dashboard',

            },
            {
              text: 'Catégorie de camion',
              active: true,

            },
          ],
        },
      },

      // Type de camion
      {
        path: '/espace-admin/:slug/type-camion',
        name: 'espace-admin.transtype',
        component: () => import('@/views/app/espace-admin/settings/modele-de-camion/type-de-camion/TypeCamion.vue'),
        meta: {
          pageTitle: 'Type de camion',
          redirectIfLoggedIn: true,

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/espace-admin/dashboard',

            },
            {
              text: 'Type de camion',
              active: true,

            },
          ],
        },
      },

      // Catégorie de marchandise
      {
        path: '/espace-admin/categorie-marchandise',
        name: 'espace-admin.marchandise',
        component: () => import('@/views/app/espace-admin/settings/marchandise/categorie-de-marchandise/CategorieMarchandise.vue'),
        meta: {
          pageTitle: 'Catégorie de marchandise',
          redirectIfLoggedIn: true,

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/espace-admin/dashboard',

            },
            {
              text: 'Catégorie de marchandise',
              active: true,

            },
          ],
        },
      },

      // Type de marchandise
      {
        path: '/espace-admin/:slug/marchandise',
        name: 'espace-admin.marchandisetype',
        component: () => import('@/views/app/espace-admin/settings/marchandise/type-de-marchandise/TypeMarchandise.vue'),
        meta: {
          pageTitle: 'Marchandise',
          redirectIfLoggedIn: true,

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/espace-admin/dashboard',

            },
            {
              text: 'Marchandise',
              active: true,

            },
          ],
        },
      },
      // Type de marchandise
      {
        path: '/espace-admin/bilan',
        name: 'espace-admin.bilan',
        component: () => import('@/views/app/espace-admin/settings/expertises/DetailsExperts.vue'),
        meta: {
          pageTitle: 'Bilan des expertises',
          redirectIfLoggedIn: true,

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/espace-admin/dashboard',

            },
            {
              text: 'Bilan des expertises',
              active: true,

            },
          ],
        },
      },
      // Type de marchandise
      {
        path: '/espace-admin/bilan/:id',
        name: 'espace-admin.detail-bilan',
        component: () => import('@/views/app/espace-admin/settings/expertises/DetailBilan.vue'),
        meta: {
          pageTitle: 'Bilan des expertises',
          redirectIfLoggedIn: true,

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/espace-admin/dashboard',

            },
            {
              text: 'Bilan par expertise',
              active: true,
            },
          ],
        },
      },

      // Statistique
      {
        path: '/espace-admin/statistiques',
        name: 'espace-admin.statistiques',
        component: () => import('@/views/app/espace-admin/settings/statistique/Statistique.vue'),
        meta: {
          pageTitle: 'Statistique',
          redirectIfLoggedIn: true,

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/espace-admin/dashboard',

            },
            {
              text: 'Statistique',
              active: true,

            },
          ],
        },
      },
      // Profile
      {
        path: '/espace-admin/profile',
        name: 'espace-admin.profile',
        component: () => import('@/views/app/espace-admin/settings/activate-account/manage-profile/AccountSetting.vue'),
        meta: {
          pageTitle: 'Gestion de profil',
          redirectIfLoggedIn: true,

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/espace-admin/dashboard',

            },
            {
              text: 'Gestion de profil',
              active: true,

            },
          ],
        },
      },
      {
        path: '/espace-admin/liste-piece/:id',
        name: 'espace-admin.liste.piece',
        component: () => import('@/views/app/espace-admin/settings/activate-account/ValiderPiece.vue'),
        meta: {
          pageTitle: 'Validation des pièces',
          redirectIfLoggedIn: true,

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/espace-admin/dashboard',

            },
            {
              text: 'Liste des pièces',
              active: true,

            },
          ],
        },
      },
      {
        path: '/espace-admin/tracking',
        name: 'espace-admin.tracking',
        component: () => import('@/views/app/espace-admin/tracking/Tracking.vue'),
        meta: {
          pageTitle: 'Gestion du tracking',
          redirectIfLoggedIn: true,

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/espace-admin/dashboard',

            },
            {
              text: 'Tracking',
              active: true,

            },
          ],
        },
      },

    ],
  },
]
