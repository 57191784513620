// eslint-disable-next-line import/no-cycle
import axiosClient from '@/helpers/axios'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const authStore = {
  state: {
    authenticated: false,
    user: null,
    token: null,
  },
  mutations: {
    setUser(state, user) {
      state.user = JSON.stringify(user)
      state.authenticated = true
    },
    async initializeAuth(state) {
      // Initialiser l'authentification si l'utilisateur était bien connecté
      const response = await axiosClient.get('/user')

      if (response.status === 401) {
        state.token = null
        state.authenticated = false
        state.commit('setUser', {})
        localStorage.clear()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            text: '👋 Chocolate oat cake jelly oat cake candy jelly beans pastry.',
            variant: 'danger',
          },
        })
        await router.push({ name: 'auth-login' })
      } else {
        state.authenticated = true
        state.user = JSON.stringify(response.data)
      }
    },

  },
}

export default authStore
